//React
import React from 'react'

//Gatsby
import { Link } from 'gatsby'

//Assets
// import Logo from '../../images/svg/piconext-logo-04.svg'
import Logo from '../../images/svg/sidepath-logo.svg'

const Nav = () => (
  <nav class="navbar navbar-expand-lg navbar-light bg-light navbar-minimal">
    <div class="container">
      <Link
        class="navbar-brand"
        aria-label="Sidepath Home"
        activeClassName="navbar-brand"
        to="/"
      >
        <Logo />
      </Link>
      {/* <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarText"
        aria-controls="navbarText"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="navbar-toggler-icon" />
      </button> */}
      {/* <div class="collapse navbar-collapse navbar-main" id="navbarText" /> */}
    </div>
  </nav>
)

export default Nav
